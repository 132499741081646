import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import A4 from "../Images/A4";
import HtmlParser from "../HtmlParser";

const OfficialListItem = ({content}) => (
    <div className="card official-list-item has-background-light">
        <div className="card-image">
            {content.relationships.image ? (
                <GatsbyImage
                    image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                    alt={content.title} />
            ) : (
                <A4 alt={content.title}/>
            )}
        </div>
        <div className="card-content">
            <p className="title is-5">{content.title}</p>
            <div className="content">
                <HtmlParser html={content.presentation.value}/>
            </div>
        </div>
    </div>
)

OfficialListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default OfficialListItem;
