import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import Title from "../components/Title";
import OfficialListItem from "../components/ListItems/OfficialListItem";
import { GatsbyImage } from "gatsby-plugin-image";
import A4 from "../components/Images/A4";
import HtmlParser from "../components/HtmlParser";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const OfficialList = ({pageContext, data, location}) => {
    const {
        breadcrumb: {crumbs},
        currentPage,
        numPages,
        basePath
    } = pageContext

    const officials = data.officials.nodes

    const officialsByFunction = [];

    officials.forEach((node) => {
        let group = node.relationships.function

        if (officialsByFunction[group.drupal_internal__tid] === undefined) {
            officialsByFunction[group.drupal_internal__tid] = {
                name: group.name,
                weight: group.weight,
                nodes: []
            };
        }

        officialsByFunction[group.drupal_internal__tid].nodes.push(node);
    });

    const sortedOfficialsByFunction = officialsByFunction.sort((a, b) => Number(a.weight) - Number(b.weight))

    return (
        <Layout>
            <Metas title="Élus et membres du conseil"/>
            <section className="section page-breadcrumb is-small has-background-light">
                <div className="container">
                    <CustomBreadcrumb crumbs={crumbs}/>
                </div>
            </section>
            <section className="section page-content">
                <div className="container">
                    <Title title="Élus et membres du conseil" className="has-text-centered" />

                    {sortedOfficialsByFunction.map((group, index) => {
                        return index === 0 ? (
                            <div key={index} className={`group term-${index}`}>
                                <h2 className="title is-2">{group.name}</h2>
                                <div className="columns is-multiline">
                                    {group.nodes.map((node, index) => (
                                        <div key={index} className="column is-12 is-9-desktop">
                                            <div className="official-list-item is-mayor">
                                                <div className="columns is-gapless">
                                                    <div className="column is-4">
                                                        {node.relationships.image ? (
                                                            <GatsbyImage
                                                                image={node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                                                                alt={node.title} />
                                                        ) : (
                                                            <A4 alt={node.title}/>
                                                        )}
                                                    </div>
                                                    <div className="column is-8">
                                                        <div className="column-wrapper has-background-light">
                                                            <p className="title is-4">{node.title}</p>
                                                            <div className="content">
                                                                <HtmlParser html={node.presentation.value}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div key={index} className={`group term-${index}`}>
                                <h2 className="title is-2">{group.name}</h2>
                                <div className="columns is-multiline">
                                    {group.nodes.map((node, index) => (
                                        <div key={index} className="column is-6 is-3-desktop">
                                            <OfficialListItem content={node} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    })}

                    <Pagination
                        path={location.pathname}
                        currentPage={currentPage}
                        numPages={numPages}
                        basePath={basePath}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default OfficialList

export const query = graphql`
    query officialListQuery($skip: Int!, $limit: Int!) {
        officials: allNodeElu (
            filter: {status: {eq: true}}
            sort: { fields: relationships___field_fonction___weight, order: ASC }
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                id
                title
                presentation: field_presentation {
                    value
                }
                image: field_image {
                    alt
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 400
                                    height: 480
                                    quality: 100
                                    transformOptions: {cropFocus: NORTH}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                    function: field_fonction {
                        name
                        weight
                        drupal_internal__tid
                    }
                }
            }
        }
    }`